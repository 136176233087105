import React, { useEffect, useReducer, useState } from "react";
import {
  validate,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from "../../../../util/validators";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";

import { Link, useNavigate, useParams } from "react-router-dom";
import { RiDeleteBinFill } from "react-icons/ri";
import { FaTasks } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { FaCcVisa } from "react-icons/fa";
import { AiOutlineFileDone } from "react-icons/ai";
import { GiProfit } from "react-icons/gi";
import DateFilterComponent from "../../../DateFilter";
import TasksTable from "../../../TasksTable/TasksTable";
import instance from "../../../../axios";

//clientName validation
const clientNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.clientName,
        isvalid: validate(action.clientName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//owner validation
const ownerReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.owner,
        isvalid: validate(action.owner, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//clientEmail validation
const clientEmailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.clientEmail,
        isvalid: validate(action.clientEmail, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//country validation
const countryReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.country,
        isvalid: validate(action.country, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

//number validation
const numberReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.number,
        isvalid: validate(action.number, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const ClientDetails = () => {
  const [tasksCount, setTasksCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalGain, setTotalGain] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  let { id } = useParams();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const [client, setClient] = useState([]);
  const [countries, setCountries] = useState([]);
  const [clientAccount, setClientAccount] = useState();
  const [clientTasks, setClientTasks] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [selectedRange, setSelectedRange] = useState({ start, end });
  const [paymentMethod, setPaymentMethod] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const params = {
          client: id,
          page,
          limit,
          start: selectedRange.start,
          end: selectedRange.end,
          isPage: true
        };
        const tasksResponse = await instance.get(`/task`, {
          params,
        });
        const clientResponse = await instance.get(`/client/${id}`);
        const currencyResponse = await instance.get(`/currency/valid/list`);
        const countryResponse = await instance.get(`/country/`);
        console.log(clientResponse.data);
        console.log(tasksResponse.data);

        setClientTasks(tasksResponse.data.tasks);
        setTasksCount(tasksResponse.data.tasksCount);
        setCompletedCount(tasksResponse.data.completedTasksCount);
        setTotalGain(tasksResponse.data.totalGain);
        setTotalProfit(tasksResponse.data.totalProfit);
        setTotalCost(tasksResponse.data.totalCost);
        setClient(clientResponse.data.client);
        setClientAccount(clientResponse.data.clientAccount);
        setCurrencies(currencyResponse.data.currencies);
        setCountries(countryResponse.data.countries);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, selectedRange]);

  //currency value
  const [currency, setCurrency] = useState(
    client.currency && client.currency.currencyname
  );

  //clientName validation
  const [clientNameState, dispatch] = useReducer(clientNameReducer, {
    value: client.clientname,
    isvalid: false,
    isTouched: false,
  });

  const clientNameChangeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      clientName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const clientNameTouchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };
  //owner validation
  const [ownerState, dispatch9] = useReducer(ownerReducer, {
    value: client.ownerName,
    isvalid: false,
    isTouched: false,
  });

  const ownerChangeHandler = (event) => {
    dispatch9({
      type: "CHANGE",
      owner: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const ownerTouchHandler = () => {
    dispatch9({
      type: "TOUCH",
    });
  };

  //clientEmail validation
  const [clientEmailState, dispatch2] = useReducer(clientEmailReducer, {
    value: client.website,
    isvalid: false,
    isTouched: false,
  });

  const clientEmailChangeHandler = (event) => {
    dispatch2({
      type: "CHANGE",
      clientEmail: event.target.value,
      validators: [VALIDATOR_MINLENGTH(6)],
    });
  };
  const clientEmailTouchHandler = () => {
    dispatch2({
      type: "TOUCH",
    });
  };

  //country value
  const [country, setCountry] = useState("");
  const countryChangeHandler = (newOne) => {
    setCountry(newOne);
  };

  //Number validation
  const [numberState, dispatch5] = useReducer(numberReducer, {
    value: client.phone,
    isvalid: false,
    isTouched: false,
  });

  const numberChangeHandler = (event) => {
    dispatch5({
      type: "CHANGE",
      number: event.target.value,
      validators: [VALIDATOR_MINLENGTH(11)],
    });
  };
  const numbertouchHandler = () => {
    dispatch5({
      type: "TOUCH",
    });
  };
  //////////////////////////////////////
  const editClientHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/client/${client._id}`,
        {
          clientName: clientNameState.value || client.clientname,
          owner: ownerState.value || client.ownerName,
          website: clientEmailState.value || client.website,
          country: country || client.country,
          phone: numberState.value || client.phone,
          currency: currency || client.currency._id,
          method: paymentMethod,
          accountNumber,
        }
      );
      const responseData = await response;
      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setError({ type: "success", message: responseData.data.message });
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      if (err.response) {
        setError({ type: "error", message: err.response.data.err });
      } else {
        setError({ type: "error", message: err.message });
      }
    }
  };

  //delete client
  const deleteClientHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/client/${id}`
        //  ,
        //  { headers :{
        //     'Authorization':`Bearer ${token}`
        //   }
        // }
      );
      const responseData = await response;
      setError(responseData.data.message);
      setIsLoading(false);
      window.location.href = "/clients";
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };
  //error message
  const errorHandler = () => {
    setError(null);
    window.location.reload(true);
  };

  const navigate = useNavigate();

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange({ start, end });
      setPage(1);
    }
  }, [start, end]);

  const onFirstPage = () => {
    setPage(1);
  };
  const onLastPage = () => {
    setPage(Math.ceil(tasksCount / limit));
  };
  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const onPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal message={error} onClear={errorHandler} />

      <div className="relative flex items-center justify-between w-full p-1 mb-4">
        {/* <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/clients")}
        >
          <TiArrowBack />
        </button> */}
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          Client Details
        </h2>
        <div>
          <DateFilterComponent
            startDate={start}
            endDate={end}
            onChange={onDateChange}
          />
        </div>
      </div>

      <div className="relative flex items-center justify-betwen border-2 space-x-8 rounded-md shadow pr-4 py-4 bg-[#F4F7FC]">
        <button
          className="absolute top-2 right-1.5 w-10 h-10"
          onClick={deleteClientHandler}
        >
          <RiDeleteBinFill className="text-gray-400 w-10 h-10" />
        </button>

        <div className="w-1/2 grid grid-cols-2 gap-4">
          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Client Name</label>
            <p className={!edit ? "ml-2 text-gray-500 font-medium" : "hidden"}>
              {client.clientname}
            </p>
            {edit && (
              <input
                type="text"
                placeholder={client.clientname}
                value={clientNameState.value}
                onChange={clientNameChangeHandler}
                onBlur={clientNameTouchHandler}
                isvalid={clientNameState.isvalid.toString()}
                className={`w-11/12 ml-2 rounded-sm p-2 ${
                  !clientNameState.isvalid &&
                  clientNameState.isTouched &&
                  "border-red-500"
                }`}
              />
            )}
          </div>
          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Owner</label>
            <p className={!edit ? "ml-2 text-gray-500 font-medium" : "hidden"}>
              {client.ownerName}
            </p>
            {edit && (
              <input
                type="text"
                placeholder={client.ownerName}
                value={ownerState.value}
                onChange={ownerChangeHandler}
                onBlur={ownerTouchHandler}
                isvalid={ownerState.isvalid.toString()}
                className={`w-11/12 ml-2 rounded-sm p-2 ${
                  !ownerState.isvalid &&
                  ownerState.isTouched &&
                  "border-red-500"
                }`}
              />
            )}
          </div>
          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Website</label>
            <p className={!edit ? "ml-2 text-gray-500 font-medium" : "hidden"}>
              {client.website}
            </p>
            {edit && (
              <input
                type="text"
                placeholder={client.website}
                value={clientEmailState.value}
                onChange={clientEmailChangeHandler}
                onBlur={clientEmailTouchHandler}
                isvalid={clientEmailState.isvalid.toString()}
                className={`w-11/12 ml-2 rounded-sm p-2 ${
                  !clientEmailState.isvalid &&
                  clientEmailState.isTouched &&
                  "border-red-500"
                }`}
              />
            )}
          </div>
          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Phone</label>
            <p className={!edit ? "ml-2 text-gray-500 font-medium" : "hidden"}>
              {client.phone}
            </p>
            {edit && (
              <input
                type="text"
                placeholder={client.phone}
                value={numberState.value}
                onChange={numberChangeHandler}
                onBlur={numbertouchHandler}
                isvalid={numberState.isvalid.toString()}
                className={`w-11/12 ml-2 rounded-sm p-2 ${
                  !numberState.isvalid &&
                  numberState.isTouched &&
                  "border-red-500"
                }`}
              />
            )}
          </div>
          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Country</label>
            <p className={!edit ? "ml-2 text-gray-500 font-medium" : "hidden"}>
              {client.country && client.country.countryName}
            </p>
            {edit && (
              <select
                id="country"
                name="country"
                className="w-11/12 ml-2 rounded-sm p-2"
                value={country}
                onChange={(event) => countryChangeHandler(event.target.value)}
              >
                <option disabled selected className="text-secondary">
                  Unchanged
                </option>
                {countries.map((country) => (
                  <option value={country._id} key={country._id}>
                    {country.countryName}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="flex flex-col w-full">
            <label className="w-full font-bold">Currency</label>
            <p className={!edit ? "ml-2 text-gray-500 font-medium" : "hidden"}>
              {client.currency && client.currency.currencyname}
            </p>
            {edit && (
              <select
                id="currencies"
                name="currencies"
                className="w-11/12 ml-2 rounded-sm p-2"
                value={currency}
                onChange={(event) => setCurrency(event.target.value)}
              >
                <option disabled selected className="text-secondary">
                  Unchanged
                </option>
                {currencies.map((currency) => (
                  <option value={currency._id} key={currency._id}>
                    {currency.currencyname}
                  </option>
                ))}
              </select>
            )}
          </div>

          {edit && (
            <>
              <div className="flex flex-col w-full">
                <label className="w-full font-bold">Payment Method</label>
                <input
                  type="text"
                  placeholder="Payment Method"
                  value={paymentMethod}
                  onChange={(event) => setPaymentMethod(event.target.value)}
                  className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
                />
              </div>

              <div className="flex flex-col w-full">
                <label className="w-full font-bold">Account Number</label>
                <input
                  type="text"
                  placeholder="Account Number"
                  value={accountNumber}
                  onChange={(event) => setAccountNumber(event.target.value)}
                  className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
                />
              </div>
            </>
          )}

          <div className="col-span-2 flex items-center justify-center space-x-2">
            {edit ? (
              <button
                // disabled={
                //   !clientEmailState.isvalid &&
                //   !ownerState.isvalid &&
                //   !clientNameState.isvalid &&
                //   !numberState.isvalid &&
                //   !country &&
                //   !currency
                // }
                type="button"
                className="bg-green-500 rounded-sm transition-all hover:bg-green-400 text-white px-3 py-1"
                onClick={editClientHandler}
              >
                Save
              </button>
            ) : (
              <button
                type="button"
                className="bg-cyan-600 rounded-sm transition-all hover:bg-cyan-400 text-white px-12 py-1"
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Edit
              </button>
            )}
            {edit && (
              <button
                type="button"
                className="bg-red-500 rounded-sm transition-all hover:bg-red-400 text-white px-3 py-1"
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>

        <div className="w-1/2">
          <div className="grid grid-cols-2 gap-2">
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <FaTasks className="bg-blue-100 text-blue-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Tasks Count</h6>
                <h4 className="font-light ml-1 my-0 p-0">{tasksCount}</h4>
              </div>
            </div>
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <AiOutlineFileDone className="bg-orange-100 text-orange-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">
                  Completed Count
                </h6>
                <h4 className="font-light ml-1 my-0 p-0">{completedCount}</h4>
              </div>
            </div>
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <FaCoins className="bg-green-100 text-green-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Client Gain</h6>
                <h4 className="font-light ml-1 my-0 p-0">{totalGain}</h4>
              </div>
            </div>
            <div className="bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2.5">
              <GiProfit className="bg-green-100 text-green-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">Total Profit</h6>
                <h4 className="font-light ml-1 my-0 p-0">{totalProfit}</h4>
              </div>
            </div>
            <div className="col-span-2 w-1/2 bg-white rounded drop-shadow flex items-center space-x-2 px-4 py-2 mx-auto">
              <FaCcVisa className="bg-purple-100 text-purple-500 w-10 h-10 p-2 rounded" />
              <div>
                <h6 className="m-0 p-0 text-sm font-semibold">
                  Account Details
                </h6>
                <h4 className="font-light ml-1 my-0 p-0">
                  {clientAccount &&
                    clientAccount.map((acc) => (
                      <div className="text-base" key={acc._id}>
                        <Link to={`/account/${acc._id}`} className="">
                          Click Here
                        </Link>
                      </div>
                    ))}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-3 drop-shadow">
        {clientTasks && clientTasks.length > 0 ? (
          <TasksTable
            pagination
            page={page}
            totalPages={Math.ceil(tasksCount / limit)}
            onFirstPage={onFirstPage}
            onLastPage={onLastPage}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            tasks={clientTasks}
          />
        ) : (
          <div className="container mx-auto p-4">
            <h3 className="text-lg font-medium text-center text-red-500">
              No Tasks
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientDetails;
