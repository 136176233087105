import React, { useState, useEffect } from "react";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { NumericFormat } from "react-number-format";
import { MdDeleteOutline } from "react-icons/md";
import axios from "../../../../axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import Pagination from "../../../Pagination";
import Filter from "../../../Filter";

const Spendings = ({}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [totalPages, setTotalPages] = useState(1);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [spendings, setSpendings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [bankId, setBankId] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const categoriesResponse = await axios.get("/spending/category");
        setCategories(categoriesResponse.data.spendingCategory);
        const bankAccountsResponse = await axios.get("/bank");
        setAccounts(bankAccountsResponse.data);
        console.log(bankAccountsResponse.data);
        const subCategoriesResponse = await axios.get("/spending/subCategory");
        setSubCategories(subCategoriesResponse.data.spendingSubCategory);
      } catch (error) {
        if (error.response) {
          setMessage({ type: "error", message: error.response.data.err });
        } else {
          setMessage({ type: "error", message: error.message });
          console.log(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const params = {
          category,
          subCategory,
          page,
          limit,
          start,
          end,
          bankId,
        };
        const { data } = await axios.get("/spending", { params });
        setSpendings(data.spending);
      } catch (error) {
        if (error.response) {
          setMessage({ type: "error", message: error.response.data.err });
        } else {
          setMessage({ type: "error", message: error.message });
          console.log(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, start, end, category, subCategory, bankId]);

  const deleteSpending = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`/spending/${id}`);
      setSpendings((prev) => prev.filter((item) => item._id !== id));
    } catch (error) {
      if (error.response) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`transition-all min-h-[calc(100vh-65px)] ${
        filterOpen ? "ml-44" : "ml-16"
      } py-4 flex flex-col space-y-2`}
    >
      <Filter filterOpen={filterOpen} setFilterOpen={setFilterOpen}>
        <select
          className="w-full"
          name="category"
          onChange={(e) => setCategory(e.target.value)}
        >
          <option selected disabled value="">
            Category
          </option>
          <option value="">All</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        <select
          className="w-full"
          name="subCategory"
          onChange={(e) => setSubCategory(e.target.value)}
        >
          <option selected disabled value="">
            Sub-Category
          </option>
          <option value="">All</option>
          {subCategories.map((subCategory) => (
            <option key={subCategory} value={subCategory}>
              {subCategory}
            </option>
          ))}
        </select>
        <select
          className="w-full"
          name="bankId"
          onChange={(e) => setBankId(e.target.value)}
        >
          <option selected disabled value="">
            Bank
          </option>
          <option value="">All</option>
          {accounts.map((account) => (
            <option key={account._id} value={account._id}>
              {account.title} - {account.currency.currencyname}
            </option>
          ))}
        </select>
      </Filter>
      {message && (
        <ErrorModal message={message} onClear={() => setMessage(null)} />
      )}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">Spendings</h1>
        <Link
          to="/add-spending"
          className="no-underline flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          <FaPlus className="w-5 h-5" />
          <span>Add Spend</span>
        </Link>
      </div>

      {isLoading ? (
        <LoadingSpinner asOverlay />
      ) : (
        <div>
          <table className="table-auto w-full rounded-lg overflow-hidden text-center drop-shadow">
            <thead>
              <tr className="drop-shadow bg-white text-gray-700">
                <th className="w-1 font-medium text-sm">
                  {/* <input type="checkbox" onChange={handleCheckAll} /> */}
                </th>
                <th className="px-4 py-3 font-medium text-sm">Amount</th>
                <th className="px-4 py-3 font-medium text-sm">Category</th>
                <th className="px-4 py-3 font-medium text-sm">Sub-Categry</th>
                <th className="px-4 py-3 font-medium text-sm">Description</th>
                <th className="px-4 py-3 font-medium text-sm">Date</th>
                <th className="px-4 py-3 font-medium text-sm">Delete</th>
              </tr>
            </thead>
            <tbody>
              {spendings.length > 0 ? (
                spendings.map((spending, index) => (
                  <tr key={index}>
                    <td className="p-2 bg-white">
                      {/* <input
                        type="checkbox"
                        checked={selectedSpendings.includes(spending._id)}
                        onChange={(e) => handleCheck(e, spending._id)}
                      /> */}
                    </td>
                    <td className="p-2 bg-white">
                      <NumericFormat
                        displayType={"text"}
                        value={spending.amount}
                        suffix={` EGP`}
                        thousandSeparator
                      />
                    </td>
                    <td className="p-2 bg-white">{spending.category}</td>
                    <td className="p-2 bg-white">{spending.subCategory}</td>
                    <td className="p-2 bg-white">{spending.description}</td>
                    <td className="p-2 bg-white">
                      {new Date(spending.createdAt).toLocaleDateString()}
                    </td>
                    <td className="p-2 bg-white">
                      <button
                        onClick={() => deleteSpending(spending._id)}
                        className="text-white bg-red-800 p-2 rounded active:scale-95"
                      >
                        <MdDeleteOutline className="" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="p-2 bg-white">
                    No Spendings
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onNextPage={() => setPage(page + 1)}
              onPreviousPage={() => setPage(page - 1)}
              onFirstPage={() => setPage(1)}
              onLastPage={() => setPage(totalPages)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Spendings;
