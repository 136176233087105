import React from "react";
import { Link } from "react-router-dom";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

const TransactionsTable = ({
  transactions,
  selectedTransactions,
  setSelectedTransactions,
}) => {
  const handleSelect = (e, transaction) => {
    if (e.target.checked) {
      setSelectedTransactions((prev) => [...prev, transaction]);
    } else {
      setSelectedTransactions((prev) =>
        prev.filter((t) => t._id !== transaction._id)
      );
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedTransactions(transactions);
    } else {
      setSelectedTransactions([]);
    }
  };

  return (
    <table className="table-auto w-full rounded-lg overflow-hidden text-center drop-shadow">
      <thead>
        <tr className="drop-shadow bg-white text-cyan-600">
          {selectedTransactions && (
            <th className="w-1 font-medium text-sm">
              <div className="relative">
                <input
                  checked={selectedTransactions.length === transactions.length}
                  onChange={handleSelectAll}
                  type="checkbox"
                  className="peer appearance-none w-4 h-4 border-2 border-gray-300 rounded-sm checked:bg-blue-600 checked:border-transparent focus:outline-none"
                />
                <FaCheck className="h-2.5 w-2.5 absolute text-white top-1 left-1/2 -translate-x-1/2 pointer-events-none hidden peer-checked:block" />
              </div>
            </th>
          )}
          <th className="px-4 py-3 font-medium text-sm">By</th>
          <th className="px-4 py-3 font-medium text-sm">Serial Number</th>
          <th className="px-4 py-3 font-medium text-sm">Title</th>
          <th className="px-4 py-3 font-medium text-sm">Status</th>
          <th className="px-4 py-3 font-medium text-sm">Amount</th>
          <th className="px-4 py-3 font-medium text-sm">Date</th>
          <th className="px-4 py-3 font-medium text-sm">Paid</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction, index) => (
          <tr
            key={transaction._id}
            className={`${!transaction.task ? "bg-blue-200" : "bg-white"} ${
              index !== 0 && "border-t-4 border-[#F4F7FC]"
            }`}
          >
            {selectedTransactions && (
              <td className="px-4 py-3">
                <div className="relative">
                  <input
                    checked={selectedTransactions.some(
                      (t) => t._id === transaction._id
                    )}
                    onChange={(e) => handleSelect(e, transaction)}
                    type="checkbox"
                    name="checked"
                    id="checked"
                    className="peer appearance-none w-4 h-4 border-2 border-gray-300 rounded-sm checked:bg-blue-600 checked:border-transparent focus:outline-none"
                  />
                  <FaCheck className="h-2.5 w-2.5 absolute text-white top-1 left-0.5 pointer-events-none hidden peer-checked:block" />
                </div>
              </td>
            )}
            <td className="px-4 py-e">
              {transaction.user ? transaction.user.fullname : "System"}
            </td>
            <td className="px-4 py-3">
              {transaction.task ? (
                <Link
                  className="flex justify-center hover:text-blue-600"
                  to={`/task/${transaction.task?._id}`}
                >
                  {transaction.task?.serialNumber}
                </Link>
              ) : (
                "System"
              )}
            </td>
            <td className="px-4 py-3">
              {!transaction.task
                ? transaction.method
                : transaction.method === "edit"
                ? `${transaction.task?.title}-${transaction.method}`
                : transaction.task?.title}
            </td>
            <td className="px-4 py-3">
              {!transaction.task
                ? "-"
                : transaction.task?.taskStatus?.statusname}
            </td>
            <td className="px-4 py-3">{transaction.amount}</td>
            <td className="px-4 py-3">
              {new Date(transaction.createdAt).toLocaleDateString()}
            </td>
            <td className="px-4 py-3 flex items-center">
              {transaction.finished ? (
                <FaCheck className="text-green-600 h-6 w-6 mx-auto" />
              ) : (
                <FaXmark className="text-red-600 h-6 w-6 mx-auto" />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TransactionsTable;
