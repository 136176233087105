import React, { useEffect, useReducer, useState } from "react";
import { validate, VALIDATOR_MINLENGTH } from "../../../../util/validators";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";

import { useNavigate, useParams } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import { RiDeleteBinFill } from "react-icons/ri";
import { TiArrowBack } from "react-icons/ti";
import { ImCancelCircle } from "react-icons/im";

//statusName validation
const statusNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.statusName,
        isvalid: validate(action.statusName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const StatusDetails = () => {
  const [edit, setEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  let { id } = useParams();

  const [status, setStatus] = useState([]);
  const [Name, setName] = useState("");

  useEffect(() => {
    let timerId;
    if (loading) {
      setIsLoading(true);
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/status/${id}`)
          .then((res) => {
            setStatus(res.data.message);
            setName(res.data.message.statusname);
          });
        setLoading(false);
        setIsLoading(false);
      });
    }
    return () => clearTimeout(timerId);
  }, [loading]);

  const [role, setRole] = useState();
  //statusName validation
  const [statusNameState, dispatch] = useReducer(statusNameReducer, {
    value: status.statusname,
    isvalid: false,
    isTouched: false,
  });

  const statusNameChangeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      statusName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const statusNameTouchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  //////////////////////////////////////
  const editStatusHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/status/${status._id}`,
        {
          name: statusNameState.value,
          role: role,
        }
      );
      const responseData = await response;

      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setError(responseData.data.message);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };

  //delete user
  const deleteStatusHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/status/${id}`
        //  ,
        //  { headers :{
        //     'Authorization':`Bearer ${token}`
        //   }
        // }
      );
      const responseData = await response;

      setError(responseData.data.message);
      setIsLoading(false);
      window.location.href = "/statuses";
    } catch (err) {
      setIsLoading(false);
      setError(err.message || "SomeThing Went Wrong , Please Try Again .");
    }
  };
  //error message
  const errorHandler = () => {
    setError(null);
    window.location.reload(true);
  };

  const navigate = useNavigate();

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal error={error} onClear={errorHandler} />

      <div className="relative flex items-center justify-center w-full p-1 mb-4">
        <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/statuses")}
        >
          <TiArrowBack />{" "}
        </button>
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          Status Details
        </h2>
      </div>

      <div className="grid grid-cols-2 gap-4 p-4 bg-white rounded drop-shadow">
        {status.changable && (
          <div className="col-span-2 flex justify-end">
            <button className="text-red-500" onClick={deleteStatusHandler}>
              <RiDeleteBinFill className="h-8 w-8" />
            </button>
          </div>
        )}

        <div>
          <p className="text-gray-500 font-semibold m-0 p-0">Status Name:</p>
          <p className={!edit ? "font-bold ml-2" : "hidden"}>
            {status.statusname}
          </p>

          <div className={edit ? "" : "hidden"}>
            <input
              type="text"
              placeholder={status.statusname}
              value={statusNameState.value}
              onChange={statusNameChangeHandler}
              onBlur={statusNameTouchHandler}
              isvalid={statusNameState.isvalid.toString()}
              className={`w-full p-2 ${
                !statusNameState.isvalid &&
                statusNameState.isTouched &&
                "border-2 border-red-500"
              }`}
            />
          </div>
        </div>

        <div>
          <p className="text-gray-500 font-semibold m-0 p-0"> Role :</p>
          <p className={!edit ? "font-bold ml-2" : "hidden"}>{status.role}</p>
          <div className={edit ? "" : "hidden"}>
            <select
              id="role"
              name="role"
              className="p-2 w-full"
              value={role}
              onChange={(event) => setRole(event.target.value)}
            >
              <option disabled value="" className="text-secondary">
                Select Role
              </option>
              <option value="admin" className="">
                Admin
              </option>
              <option value="specialistService" className="">
                SpecialistService
              </option>
              <option value="customerService" className="">
                CustomerService
              </option>
              <option value="all" className="">
                All
              </option>
            </select>
          </div>
        </div>

        {/* /////////////////////// */}

        {status.changable ? (
          <div className="col-span-2 flex justify-center">
            {!edit ? (
              <button
                className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Edit
              </button>
            ) : (
              ""
            )}
            {edit && (
              <div className="flex space-x-1">
                <button
                  disabled={!statusNameState.isvalid && !role}
                  className="text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-600"
                  onClick={editStatusHandler}
                >
                  Submit
                </button>
                <button
                  className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-red-600"
                  onClick={() => {
                    setEdit(!edit);
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StatusDetails;
