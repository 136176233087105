import React, { useState, useRef, useEffect } from 'react';

const SpecialtySelector = ({ specialities, speciality, setSpeciality }) => {
//   const [speciality, setSpeciality] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const specialitySelectHandler = (e) => {
    const s = e.target.value;
    if (speciality.includes(s)) {
      setSpeciality((prev) => prev.filter((item) => item !== s));
    } else {
      setSpeciality((prev) => [...prev, s]);
    }
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  return (
    <>
      <div className="flex flex-col w-full" ref={dropdownRef}>
        <label htmlFor="speciality" className="w-full lg:w-1/5 font-bold">
          Speciality
        </label>
        <div className="relative w-full ml-2 lg:w-4/5">
          <button
            onClick={toggleDropdown}
            className="w-full p-2 text-left bg-white border rounded-sm focus:outline-none focus:ring-2 focus:ring-sky-600"
          >
            {speciality.length === 0 ? 'Select Speciality' : `${speciality.length} selected`}
          </button>
          {isOpen && (
            <div className="absolute z-10 w-full mt-1 bg-white border rounded-sm shadow-lg max-h-60 overflow-auto">
              {specialities.map((specialityGroup, groupIndex) => (
                <div key={groupIndex} className="p-2">
                  <h3 className="font-semibold mb-2">{specialityGroup.main}</h3>
                  {specialityGroup.sub.map((sub) => (
                    <div key={sub._id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={sub._id}
                        value={sub._id}
                        checked={speciality.includes(sub._id)}
                        onChange={specialitySelectHandler}
                        className="mr-2"
                      />
                      <label htmlFor={sub._id}>{sub.sub_speciality}</label>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Selected Specialities */}
      {speciality.length > 0 && (
        <div className="flex flex-col w-full mt-4">
          <label className="w-full lg:w-1/5 font-bold">
            Selected Specialities
          </label>
          <div className="w-full ml-2 rounded-sm lg:w-4/5 p-2 flex flex-wrap">
            {speciality.map((specialityId) => {
              const selectedSpeciality = specialities
                .flatMap((s) => s.sub)
                .find((sub) => sub._id === specialityId);
              return (
                <div
                  key={specialityId}
                  className="bg-sky-600 text-white rounded px-2 py-1 font-semibold text-sm mr-2 mb-2"
                >
                  {selectedSpeciality.sub_speciality}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default SpecialtySelector;