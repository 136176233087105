import React, { useEffect, useState, useRef } from "react";
import { GrSearch } from "react-icons/gr";
import { useNavigate } from "react-router";
import { IoIosNotificationsOutline } from "react-icons/io";
import NotificationCard from "./Notification";
import instance from "../axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const Header = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showNofications, setShowNofications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState();
  const [message, setMessage] = useState();
  const name = JSON.parse(localStorage.getItem("loggedUserName"));
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const notificationRef = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data } = await instance.get("/webNotification");
        setNotifications(data.notifications);
        setCount(data.count);
      } catch (error) {
        if (error.response) {
          setMessage({ type: "error", message: error.response.data.err });
        } else {
          setMessage({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNofications(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="bg-white fixed z-50 w-full h-16 flex items-center px-3 space-x-7 drop-shadow">
      <h1
        className="text-[#3588BA] text-2xl font-bold cursor-pointer"
        onClick={() => navigate("/")}
      >
        Smarteduservices
      </h1>
      <div className="w-full flex items-center justify-between space-x-6">
        <div className="relative flex-1 rounded-md">
          <input
            className="h-10 w-1/3 px-8 border-none ring-0"
            type="search"
            name="search"
            id="search"
            placeholder="Search..."
          />
          <GrSearch className="absolute top-3 left-2" />
        </div>
        <div className="relative rounded-full transition-all" ref={notificationRef}>
          <div className="select-none animate-bounce absolute top-0 right-0.5  bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs">
            {count}
          </div>
          <IoIosNotificationsOutline
            onClick={() => setShowNofications((prev) => !prev)}
            className="w-8 h-8 cursor-pointer"
          />
          {showNofications && (
            <div className="p-2 space-y-2 absolute w-[400px] -left-[400px] h-96 max-h-96 overflow-y-auto border bg-white rounded drop-shadow">
              {isLoading ? (
                <div className="h-full w-full flex items-center justify-center">
                  <LoadingSpinner />
                </div>
              ) : notifications && notifications.length > 0 ? (
                notifications.map((notification) => (
                  <NotificationCard
                    key={notification._id}
                    notification={notification}
                    setNotification={setNotifications}
                  />
                ))
              ) : (
                <p className="p-o m-0 h-full w-full flex items-center justify-center font-bold text-gray-500">
                  No Notifications
                </p>
              )}
            </div>
          )}
        </div>
        <div className="flex items-center space-x-4">
          <div className="w-10 h-10 bg-blue-100 rounded-full flex justify-center items-center text-center capitalize">
            {name.charAt(0)}
          </div>
          <div className="text-lg text-center">
            <p className="m-0 p-0 text-base font-">{name}</p>
            <p className="m-0 p-0 text-sm text-gray-500">
              {role.charAt(0).toUpperCase() + role.slice(1)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;