import React, { useState } from "react";
import WaitingOffer from "./Progress/WaitingOffer";
import Approved from "./Progress/Approved";
import Assigned from "./Progress/Assigned";
import Ongoing from "./Progress/Ongoing";
import Delivered from "./Progress/Delivered";
import InReview from "./Progress/InReview";

const Status = ({ status, task, setTask, offer, setOffer, percentages }) => {
  const availableStatuses = ["waiting offer", "approved", "rejected", "assigned", "on going", "edit", "delivered", "in review"];

  const [statusState, setStatusState] = useState(status);
  const renderStatus = () => {
    switch (statusState) {
      case "waiting offer":
        return (
          <WaitingOffer
            taskId={task._id}
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );

      case "approved":
        return (
          <Approved
            taskId={task._id}
            setStatus={setStatusState}
            cost={!!task.cost}
            setTask={setTask}
            offers={task.offers}
            taskCost={!!task.cost ? task.cost : null}
            task={task}
          />
        );
      case "rejected":
        return (
          <WaitingOffer
            taskId={task._id}
            rejected
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );

      case "assigned":
        return (
          <Assigned
            taskId={task._id}
            setStatus={setStatusState}
            setTask={setTask}
            offers={task.offers}
            task={task}
          />
        );

        case "on going":
          return (
            <Ongoing
              taskId={task._id}
              freelancer={task.freelancer?.freelancername}
              setStatus={setStatusState}
              setTask={setTask}
              offers={task.offers}
              task={task}
            />
          );
        
        case "in review":
          return (
            <InReview
              taskId={task._id}
              setStatus={setStatusState}
              setTask={setTask}
              offers={task.offers}
              task={task}
            />
          );

        case "delivered":
          return (
            <Delivered
              task={task}
              taskId={task._id}
              edits={task.edits}
              setTask={setTask}
              percentages={percentages}
            />
          );
        case "edit":
            return (
              <Delivered
                task={task}
                taskId={task._id}
                edits={task.edits}
                setTask={setTask}
                percentages={percentages}
              />
            );

      default:
        return;
    }
  };

  return (
    <div
      className={`${
        !availableStatuses.includes(statusState) && "hidden"
      } w-full max-w-5xl 2xl:max-w-6xl mx-auto bg-white shadow rounded p-2`}
    >
      {/* <h1 className="text-2xl text-gray-600 font-semibold mb-0">Status</h1>
      <p className="mb-2 p-0 text-gray-400 font-semibold text-sm capitalize">
        {statusState.split("-").join(" ")}
      </p> */}
      {renderStatus()}
    </div>
  );
};

export default Status;
