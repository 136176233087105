import React, { useState } from "react";
import InReview from "./Progress/InReview";

const Status = ({ status, task, setTask }) => {
  const availableStatuses = ["in review"];
  const [statusState, setStatusState] = useState(status);
  const renderStatus = () => {
    switch (statusState) {
      case "in review":
        return (
          <InReview
            taskId={task._id}
            setStatus={setStatusState}
            setTask={setTask}
            offers={task.offers}
            task={task}
          />
        );
      default:
        return;
    }
  };

  return (
    <div
      className={`${
        !availableStatuses.includes(statusState) && "hidden"
      } w-full max-w-5xl 2xl:max-w-6xl mx-auto bg-white shadow rounded p-2`}
    >
      {renderStatus()}
    </div>
  );
};

export default Status;
