import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import axios from "../../../../../axios";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../../LoadingSpinner/ErrorModal";
import { emitTaskNotification } from "../../../../../util/io";

const Approved = ({ taskId, setStatus, setTask, offers, task }) => {
  const userId = JSON.parse(localStorage.getItem("user"))._id;
  const [offersState] = useState(
    offers.filter((offer) => offer.freelancer._id === userId)
  );
  const [offerDeadline, setOfferDeadline] = useState();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [offerValue, setOfferValue] = useState(
    offers.find((offer) => offer.freelancer._id === userId)?.offer || 0
  );
  const [offerId, setOfferId] = useState(
    offers.find((offer) => offer.freelancer._id === userId)?._id || null
  );
  const initialValues = {
    offer: 0,
    offerDeadline,
    excuse: ""
  };

  const submitHandler = async (values) => {
    try {
      setIsLoading(true);
      if (!task.deadline && !values.offerDeadline) {
        alert("Please select a deadline");
        setIsLoading(false);
        return;
      }
      await axios.post(`/task/action/offer/add/${taskId}`, values);
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Freelancer Has Added Offer`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    }
  };

  const handleEdit = async () => {
    try {
      setIsLoading(true);
      await axios.post(`/task/action/offer/edit/${offerId}`, {offerValue: offerValue});
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Freelancer has edited his offer`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        setMessage({ type: "error", message: error.response.data.err });
      } else {
        setMessage({ type: "error", message: error.message });
      }
    }
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="space-y-2">
      <ErrorModal message={message} onClear={() => setMessage(null)} />
      {offers.filter((offer) => offer.freelancer._id === userId).length ===
        0 && (
        <>
          <Formik initialValues={initialValues} onSubmit={submitHandler} className="">
            {({ getFieldProps }) => (
              <Form className="flex items-end justify-between">
                <div className="w-1/3">
                  <label htmlFor="offer" className="text-gray-400 font-semibold">
                    Offer
                  </label>
                  <input type="number" {...getFieldProps("offer")} />
                </div>
                <div className="w-1/3">
                  <label htmlFor="offer" className="text-gray-400 font-semibold">
                    Deadline
                  </label>
                  <input
                    type="datetime-local"
                    id="meeting-time"
                    name="meeting-time"
                    placeholder="DeadLine"
                    onChange={(e) => setOfferDeadline(e.target.value)}
                    {...getFieldProps("offerDeadline")}
                    className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
                >
                  Submit
                </button>
              </Form>
            )}
          </Formik>
          <Formik initialValues={initialValues} onSubmit={submitHandler} className="">
            {({ getFieldProps }) => (
              <Form className="flex items-end justify-between">
                <div className="w-2/3">
                  <label htmlFor="excuse" className="text-gray-400 font-semibold">
                    Excuse Reason
                  </label>
                  <input type="text" {...getFieldProps("excuse")} />
                </div>
                <button
                  type="submit"
                  className="bg-red-500 text-white px-4 py-2 rounded active:scale-95"
                >
                  Excuse
                </button>
              </Form>
            )}
          </Formik>
        </>
      )}
      {offersState && offersState.length > 0 && (
        <table className="w-full border border-collapse">
          <thead>
            <tr>
              <th className="border p-2">Freelancer</th>
              <th className="border p-2">Offer</th>
              <th className="border p-2">Deadline</th>
              <th className="border p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {offersState.map((offer) => (
              <tr key={offer._id}>
                <td className="border p-2">
                  {offer.freelancer.freelancername}
                </td>
                <td className="border p-2">
                  {offer.excuse === "" || !offer.excuse ? (
                    <div className="flex items-center w-1/2">
                      <input
                        type="number"
                        value={offerValue}
                        onChange={(e) => setOfferValue(e.target.value)}
                        className="w-full ml-2 rounded-sm lg:w-4/5 p-2"
                      />
                    </div>
                  ) : (
                    "Excused"
                  )}
                </td>
                <td className={offer.offer_deadline > task.deadline ? "border p-2 text-red-500" : "border p-2"}>
                  {offer.excuse === "" || !offer.excuse ? (
                    `${!offer.offer_deadline ? task.deadline?.split("T")[0] : offer.offer_deadline?.split("T")[0]} ${!offer.offer_deadline ? task.deadline?.split("T")[1]?.split(".")[0] : offer.offer_deadline?.split("T")[1]?.split(".")[0]}`
                  ) : (
                    offer.excuse
                  )}
                </td>
                <td className="border p-2">
                  {offer.excuse === "" || !offer.excuse ? (
                    <button
                      onClick={handleEdit}
                      className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
                    >
                      Edit
                    </button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Approved;
