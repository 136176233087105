import React, { useEffect, useReducer, useState } from "react";
import { validate, VALIDATOR_MINLENGTH } from "../../../../util/validators";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

//userName validation
const userNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.userName,
        isvalid: validate(action.userName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//fullName validation
const fullNameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.fullName,
        isvalid: validate(action.fullName, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//password validation
const passwordReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.password,
        isvalid: validate(action.password, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};
//number validation
const numberReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.number,
        isvalid: validate(action.number, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const AddUser = () => {
  const [specialities, setSpecialities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timerId;
    if (loading) {
      setIsLoading(true);
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/speciality/`)
          .then((res) => {
            console.log(res.data);
            setSpecialities(res.data.specialities);
          });
        setLoading(false);
        setIsLoading(false);
      });
      timerId = setTimeout(async () => {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}:5001/api/country/`)
          .then((res) => {
            setCountries(res.data.countries);
          });
        setLoading(false);
        setIsLoading(false);
      });
    }
    return () => clearTimeout(timerId);
  }, [loading]);

  //userName validation
  const [userNameState, dispatch] = useReducer(userNameReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const userNameChangeHandler = (event) => {
    dispatch({
      type: "CHANGE",
      userName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const userNameTouchHandler = () => {
    dispatch({
      type: "TOUCH",
    });
  };

  //fullName validation
  const [fullNameState, dispatch2] = useReducer(fullNameReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const fullNameChangeHandler = (event) => {
    dispatch2({
      type: "CHANGE",
      fullName: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const fullNameTouchHandler = () => {
    dispatch2({
      type: "TOUCH",
    });
  };

  //password validation
  const [passwordState, dispatch3] = useReducer(passwordReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const passwordChangeHandler = (event) => {
    dispatch3({
      type: "CHANGE",
      password: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const passwordTouchHandler = () => {
    dispatch3({
      type: "TOUCH",
    });
  };

  //Number validation
  const [numberState, dispatch5] = useReducer(numberReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const numberChangeHandler = (event) => {
    dispatch5({
      type: "CHANGE",
      number: event.target.value,
      validators: [VALIDATOR_MINLENGTH(11)],
    });
  };
  const numbertouchHandler = () => {
    dispatch5({
      type: "TOUCH",
    });
  };

  const [visable, setVisable] = useState(false);

  //Role value
  const [role, setRole] = useState("");
  const RoleChangeHandler = (newOne) => {
    setRole(newOne);
    if (newOne == "specialistService") {
      setVisable(true);
    } else {
      setVisable(false);
    }
  };

  //speciality value
  const [speciality, setSpeciality] = useState("");

  const specialityChangeHandler = (newOne) => {
    setSpeciality(newOne);
  };

  //country value
  const [country, setCountry] = useState("");
  const countryChangeHandler = (newOne) => {
    setCountry(newOne);
  };

  /////////////////////////////////

  const newUserSubmitHandler = async (event) => {
    event.preventDefault();
    // send api request to validate data
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/user/`,
        {
          fullName: fullNameState.value,
          userName: userNameState.value,
          password: passwordState.value,
          userRole: role,
          speciality: speciality,
          userType: speciality,
          country: country,
          phone: numberState.value,
        }
      );

      const responseData = await response;

      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setError({ type: "success", message: responseData.data.message });
      setIsLoading(false);
      navigate("/users");
    } catch (err) {
      setIsLoading(false);
      if (err.response) {
        setError({ type: "error", message: err.response.data.err });
      } else {
        setError({ type: "error", message: err.message });
      }
    }
    fullNameState.value = "";
    userNameState.value = "";
    passwordState.value = "";
    numberState.value = "";
    setRole("");
    setSpeciality("");
    setCountry("");
  };

  const uniqueItems = specialities.filter((item, index, self) => {
    return index === self.findIndex((i) => i.speciality === item.speciality);
  });

  return (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <ErrorModal onClear={() => setError(null)} message={error} />
      {isLoading && <LoadingSpinner asOverlay />}

      <div className="relative flex flex-row justify-center w-full p-1 mb-4">
        <button
          className="absolute top-0 left-0 p-2 text-3xl"
          onClick={() => navigate("/users")}
        >
          <TiArrowBack />
        </button>
        <h2 className="text-center text-2xl font-bold lg:text-3xl">
          Add New User
        </h2>
      </div>

      <form
        className="grid grid-cols-2 gap-4 w-4/5 mx-auto"
        onSubmit={newUserSubmitHandler}
      >
        {[
          {
            label: "Full Name",
            state: fullNameState,
            handler: fullNameChangeHandler,
            touchHandler: fullNameTouchHandler,
          },
          {
            label: "Username",
            state: userNameState,
            handler: userNameChangeHandler,
            touchHandler: userNameTouchHandler,
          },
          {
            label: "Password",
            state: passwordState,
            handler: passwordChangeHandler,
            touchHandler: passwordTouchHandler,
            type: "password",
          },
          {
            label: "Phone",
            state: numberState,
            handler: numberChangeHandler,
            touchHandler: numbertouchHandler,
            type: "tel",
          },
        ].map((input, index) => (
          <div key={index} className="flex flex-col w-full">
            <label className="w-full lg:w-1/5 font-bold">{input.label}</label>
            <input
              type={input.type || "text"}
              placeholder={input.label}
              value={input.state.value}
              onChange={input.handler}
              onBlur={input.touchHandler}
              className={`w-full ml-2 rounded-sm lg:w-4/5 p-2 ${
                !input.state.isvalid &&
                input.state.isTouched &&
                "border-red-500"
              }`}
            />
          </div>
        ))}

        <div className="flex flex-col w-full">
          <label className="w-full lg:w-1/5 font-bold">Country</label>
          <select
            id="country"
            name="country"
            className="rounded-sm ml-2 w-full lg:w-4/5 p-2"
            value={country}
            onChange={(event) => countryChangeHandler(event.target.value)}
          >
            <option selected disabled value="" className="text-gray-500">
              Select Country
            </option>
            {countries.map((country) => (
              <option value={country._id} key={country._id}>
                {country.countryName}
              </option>
            ))}
          </select>
        </div>

        <div className="flex flex-col w-full">
          <label htmlFor="role" className="w-full lg:w-1/5 font-bold">
            Role
          </label>
          <select
            id="role"
            name="role"
            className="rounded-sm ml-2 w-full lg:w-4/5 p-2"
            value={role}
            onChange={(event) => RoleChangeHandler(event.target.value)}
          >
            <option selected disabled value="" className="text-gray-500">
              Select Role
            </option>
            <option value="admin">Admin</option>
            <option value="customerService">Customer Service</option>
            <option value="specialistService">Specialist Service</option>
            <option value="academicReviewer">Academic Reviewer</option>
          </select>
        </div>

        {visable && (
          <div className="flex flex-col w-full">
            <label htmlFor="speciality" className="w-full lg:w-1/5 font-bold">
              Speciality:
            </label>
            <select
              id="speciality"
              name="speciality"
              className="rounded-sm ml-2 w-full lg:w-4/5 p-2"
              value={speciality}
              onChange={(event) => specialityChangeHandler(event.target.value)}
            >
              <option selected disabled value="" className="text-gray-500">
                Select Speciality
              </option>
              {specialities.map((speciality, index) => (
                <optgroup key={index} label={speciality.main}>
                  {speciality.sub.map((sub) => (
                    <option key={sub._id} value={sub._id}>
                      {sub.sub_speciality}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
        )}

        <div className="col-span-2 flex justify-center w-full">
          <button
            disabled={
              !visable
                ? !fullNameState.isvalid ||
                  !userNameState.isvalid ||
                  !passwordState.isvalid ||
                  !numberState.isvalid ||
                  !country ||
                  !role
                : !fullNameState.isvalid ||
                  !userNameState.isvalid ||
                  !passwordState.isvalid ||
                  !country ||
                  !numberState.isvalid ||
                  !role ||
                  !speciality
            }
            className="bg-cyan-600 text-white rounded py-1 font-bold w-4/5 lg:w-1/5 transition-all hover:bg-cyan-500 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddUser;
