import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

const User = ({ user, deleteUserHandler }) => {
  const navigate = useNavigate();
  return (
    <div
      className="bg-white my-2 drop-shadow px-4 py-2 flex items-center justify-between"
      key={user._id}
    >
      <div className="flex items-center space-x-4 w-2/6">
        <div className="w-10 h-10 rounded-full bg-teal-300 flex items-center justify-center font-bold">
          {user.fullname.charAt(0).toUpperCase()}
        </div>
        <Link
          className="no-underline hover:text-blue-600"
          to={`/user/${user._id}`}
        >
          {user.fullname}
        </Link>
      </div>
      <div className="w-2/6 flex justify-center">
        <p className="m-0 bg-blue-100 rounded-md border w-1/2 text-blue-600 text-center">
          {user.user_role}
        </p>
      </div>
      <div className="w-2/6 flex items-center justify-center space-x-4">
        <p className="m-1 bg-blue-100 rounded-md border w-1/3 text-blue-600 text-center">
          {user.tasksCount}
        </p>
        <p className="m-1 bg-blue-100 rounded-md border w-1/3 text-blue-600 text-center">
          {user.completedCount}
        </p>
        <p className="m-1 bg-blue-100 rounded-md border w-1/3 text-blue-600 text-center">
          {parseInt((user.totalProfit / user.totalGain) * 100)}%
        </p>
      </div>
      <div className="w-1/6 flex items-center justify-end space-x-4">
        {user.user_role == "admin" ? (
          <button className="" disabled>
            <RiDeleteBinFill className="h-6 w-6" color="gray" />
          </button>
        ) : (
          <button onClick={() => deleteUserHandler(user._id)}>
            <RiDeleteBinFill className="h-6 w-6" color="red" />
          </button>
        )}
      </div>
    </div>
  );
};

export default User;
