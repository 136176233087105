import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import "./Tasks.css";
import { FaPlus, FaSearch, FaSortAmountDownAlt } from "react-icons/fa";
import Filter from "../../../Filter";

import GetCookie from "../../../../hooks/getCookie";
import { useNavigate } from "react-router";
import ReactDatePicker from "react-datepicker";
import DateFilter from "../../../DateFilter";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import TasksTable from "../../../TasksTable/TasksTable";
import instance from "../../../../axios";
import { useDebounce } from "use-debounce";

//search filter
const getSearchFilter = (searchName, tasks) => {
  if (!searchName) {
    return tasks;
  }
  return tasks.filter(
    (task) =>
      task.title.toLowerCase().includes(searchName.toLowerCase()) ||
      task.serialNumber.includes(searchName)
  );
};

const Tasks = () => {
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const token = GetCookie("AdminToken");
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [tasks, setTasks] = useState([]);

  const [specialities, setSpecialities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [freelancers, setFreelancers] = useState([]);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);

  const [tasksCount, setTasksCount] = useState();
  const [totalGain, setTotalGain] = useState();
  const [totalCost, setTotalCost] = useState();
  const [totalProfit, setTotalProfit] = useState();
  const [completedCount, setCompletedCount] = useState();
  const [totalProfitPercentage, setTotalProfitPercentage] = useState();
  const [filterOpen, setFilterOpen] = useState(false);
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState("");
  const [search, setSearch] = useState();

  const [speciality, setSpeciality] = useState("");
  const [subSpeciality, setSubSpeciality] = useState("");
  const [status, setStatus] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [selectedRange, setSelectedRange] = useState({ start, end });
  const [freelancer, setFreelancer] = useState("");
  const [client, setClient] = useState("");
  const [country, setCountry] = useState("");
  const [user, setUser] = useState("");
  const [sort, setSort] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        page,
        limit,
        sort,
        search,
        speciality,
        subSpeciality,
        status,
        start: selectedRange.start,
        end: selectedRange.end,
        freelancer,
        client,
        country,
        user,
      };
      setIsLoading(true);
      try {
        const [
          statusRes,
          specialityRes,
          countryRes,
          freelancerRes,
          clientRes,
          userRes,
          taskRes,
        ] = await Promise.all([
          instance.get(`/status/`),
          instance.get(`/speciality/`),
          instance.get(`/country/`),
          instance.get(`/freelancer/`),
          instance.get(`/client/`),
          instance.get(`/user/`),
          instance.get(`/task/`, {
            params,
          }),
        ]);

        setStatuses(statusRes.data.statuses);
        setSpecialities(specialityRes.data.specialities);
        setCountries(countryRes.data.countries);
        setFreelancers(freelancerRes.data.freelancers);
        setClients(clientRes.data.clients);
        setUsers(userRes.data.users);

        setTasks(taskRes.data.tasks);

        setTasksCount(taskRes.data.tasksCount);
        setTotalCost(taskRes.data.totalCost);
        setTotalGain(taskRes.data.totalGain);
        setTotalProfit(taskRes.data.totalProfit);
        setCompletedCount(taskRes.data.completedTasksCount);
        setTotalProfitPercentage(taskRes.data.totalProfitPercentage);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    page,
    limit,
    sort,
    search,
    speciality,
    subSpeciality,
    status,
    freelancer,
    client,
    user,
    country,
    selectedRange,
  ]);

  const clearFilterHandler = () => {
    setFreelancer("");
    setClient("");
    setSearchName("");
    setCountry("");
    setSpeciality("");
    setStatus("");
    setStart("");
    setEnd("");
    setUser("");
    setSubSpeciality("");
  };

  const [datePickerOpen] = useState(false);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange({ start, end });
      setPage(1);
    }
  }, [start, end]);

  const errorHandler = () => {
    setError(null);
  };

  const onFirstPage = () => {
    setPage(1);
  };
  const onLastPage = () => {
    setPage(Math.ceil(tasksCount / limit));
  };
  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const onPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div
      className={`transition-all min-h-[calc(100vh-65px)] ${
        filterOpen ? "ml-44" : "ml-16"
      } py-4 flex flex-col space-y-2`}
    >
      <ErrorModal message={error} onClear={errorHandler} />
      <Filter
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        clear={clearFilterHandler}
      >
        <select
          id="speciality"
          name="speciality"
          className="w-full"
          value={speciality}
          onChange={(e) => {
            setSpeciality(e.target.value);
          }}
        >
          <option selected disabled value="" className="text-secondary">
            Speciality
          </option>
          {specialities.map((speciality, index) => (
            <option key={index} value={speciality.main}>
              {speciality.main}
            </option>
          ))}
        </select>
        {speciality && speciality !== "" && (
          <select
            id="subSpeciality"
            name="subSpeciality"
            className="w-full"
            value={subSpeciality}
            onChange={(e) => {
              setSubSpeciality(e.target.value);
            }}
          >
            <option selected disabled value="" className="text-secondary">
              Sub Speciality
            </option>
            {specialities
              .find((s) => s.main === speciality)
              ?.sub.map((sub) => (
                <option key={sub._id} value={sub._id}>
                  {sub.sub_speciality}
                </option>
              ))}
          </select>
        )}

        <select
          id="status"
          name="status"
          className="w-full"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <option value="" className="text-secondary">
            Statuses
          </option>
          {statuses.map((status) => (
            <option value={status._id} key={status._id}>
              {status?.statusname}
            </option>
          ))}
        </select>

        <select
          id="freelancers"
          name="freelancers"
          className="w-full"
          value={freelancer}
          onChange={(e) => {
            setFreelancer(e.target.value);
          }}
        >
          <option value="" className="text-secondary">
            Freelanceres
          </option>
          {freelancers.map((freelancer) => (
            <option value={freelancer._id} key={freelancer._id}>
              {freelancer.freelancername}
            </option>
          ))}
        </select>

        <select
          id="clients"
          name="clients"
          className="w-full"
          value={client}
          onChange={(e) => {
            setClient(e.target.value);
          }}
        >
          <option value="" className="text-secondary">
            Clients
          </option>
          {clients.map((client) => (
            <option value={client._id} key={client._id}>
              {client?.clientname}
            </option>
          ))}
        </select>

        <select
          id="users"
          name="users"
          className="w-full"
          value={user}
          onChange={(e) => {
            setUser(e.target.value);
          }}
        >
          <option value="" className="text-secondary">
            Users
          </option>
          {users.map((user) => (
            <option value={user._id} key={user._id}>
              {user.username}
            </option>
          ))}
        </select>

        <select
          id="countries"
          name="countries"
          className="w-full"
          value={country}
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        >
          <option value="" className="text-secondary">
            Countries
          </option>
          {countries.map((country) => (
            <option value={country._id} key={country._id}>
              {country.countryName}
            </option>
          ))}
        </select>
      </Filter>

      <div className="flex justify-between items-center">
        <h1 className="text-2xl">Tasks</h1>
        <div className="">
          <DateFilter
            startDate={start}
            endDate={end}
            onChange={onChange}
            datePickerOpen={datePickerOpen}
          />
        </div>
      </div>

      <div className="flex items-center justify-between">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target.search.value);
          }}
          className="w-1/3 relative"
        >
          <button type="submit" className="absolute top-3 right-3">
            <FaSearch />
          </button>
          <input
            name="search"
            type="text"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            placeholder={"Search By Name or ID"}
          />
        </form>

        <button
          className="inline-flex items-center rounded-md border px-3 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
          type="button"
          onClick={() => navigate("/addtask")}
        >
          <FaPlus className="mr-2" />
          Add New Task
        </button>
      </div>

      <div className="grid grid-cols-3 gap-2">
        <div className="bg-white drop-shadow rounded flex flex-col justify-center px-4 pt-4 pb-1 border">
          <div className="flex items-center justify-between space-x-8">
            <div className="text-center">
              <h2 className="text-lg text-gray-400 font-semibold">
                Task Count
              </h2>
              <h2 className="text-2xl font-medium">
                {tasksCount ? tasksCount : "0"}
              </h2>
            </div>
            <div className="text-center">
              <h2 className="text-lg text-gray-400 font-semibold">
                Completed Count
              </h2>
              <h2 className="text-2xl font-medium">
                {completedCount ? completedCount : "0"}
              </h2>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center m-0 p-0">
            <h2 className="text-base text-sky-800 font-semibold m-0 p-0">
              Task Completion -{" "}
              {((completedCount / tasksCount) * 100).toFixed(2)} %
            </h2>
            <div className="w-2/3 bg-gray-300 rounded-full h-1.5">
              <div
                className="bg-green-400 h-1.5 rounded-full"
                style={{ width: `${(completedCount / tasksCount) * 100}%` }}
              ></div>
            </div>
          </div>
        </div>

        <div className="bg-white drop-shadow rounded col-span-2 flex justify-between p-4">
          {[
            { label: "Total Gain", data: totalGain },
            { label: "Total Cost", data: totalCost },
            { label: "Total Profit", data: totalProfit },
            { label: "Profit Percentage", data: totalProfitPercentage },
          ].map((item, index) => (
            <div
              key={item.label}
              className="relative w-1/4 h-full flex items-center justify-between space-x-0.5"
            >
              <div className="text-center h-full">
                <h2 className="text-lg text-gray-400 font-semibold">
                  {item.label}
                </h2>
                <h2 className="text-2xl font-medium">
                  {item.label == "Profit Percentage" ? (
                    <span className="text-green-500">
                      {item.data ? (item.data * 100).toFixed(2) : "0"} %
                    </span>
                  ) : (
                    <span>{item.data ? item.data : "0"}</span>
                  )}
                </h2>
              </div>

              {index !== 0 && (
                <div className="absolute top-auto -left-8 w-[1.7px] h-2/3 my bg-black my-6"></div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-end">
        <div className="w-1/5 flex items-center justify-between space-x-2">
          <FaSortAmountDownAlt />
          <select
            id="sort"
            name="sort"
            className="flex-1"
            value={sort}
            onChange={(e) => {
              setSort(e.target.value);
            }}
          >
            <option value="" selected disabled className="text-secondary">
              Sort By
            </option>
            <option value="date" className="">
              Date
            </option>
            <option value="profit" className="">
              Profit
            </option>
          </select>
        </div>
      </div>

      {tasks && tasks.length > 0 ? (
        <TasksTable
          pagination
          page={page}
          totalPages={Math.ceil(tasksCount / limit)}
          onFirstPage={onFirstPage}
          onLastPage={onLastPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          tasks={tasks}
        />
      ) : (
        <div className="text-center">
          <h2>There Is No Tasks</h2>
        </div>
      )}
    </div>
  );
};

export default Tasks;
