import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";

const User = ({ user }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-white my-2 drop-shadow px-4 py-2 flex items-center justify-between">
      <div className="flex-1 flex items-center space-x-4">
        <div className="w-10 h-10 rounded-full bg-indigo-300 flex items-center justify-center font-bold">
          {user.title && user.title.charAt(0).toUpperCase()}
        </div>
        <Link
          className="no-underline hover:text-blue-600"
          to={`/account/${user._id}`}
        >
          {user.title}
        </Link>
      </div>
      <div className="w-1/5 flex justify-center">
        <p className="m-0 bg-blue-100 px-4 rounded-md border text-blue-600 text-center">
          {user.type}
        </p>
      </div>
      <div className="w-1/5 flex justify-center">
        <p className="m-0 bg-blue-100 px-4 rounded-md border text-blue-600 text-center">
          {user.balance.toFixed(2)}
        </p>
      </div>
    </div>
  );
};

export default User;
