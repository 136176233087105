import React, { useEffect, useRef, useState } from "react";
import AttachmentsTable from "./AttachmentsTable";
import instance from "../../axios";
import ErrorModal from "../../LoadingSpinner/ErrorModal";

const Attachments = ({ task, freelancer = true }) => {
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [error, setError] = useState(null);
  const fileRef = useRef();
  const role = JSON.parse(localStorage.getItem("user"))?.user_role;

  const onAddFile = () => {
    fileRef.current.click();
  };

  useEffect(() => {
    if (!file) return;
    (async () => {
      try {
        const formData = new FormData();
        for (let i = 0; i < file.length; i++) {
          const f = file[i];
          const fileName = f.name;
          const encodedFilename = encodeURIComponent(fileName);
          formData.append("attachment", f, encodedFilename);
        }
        formData.append("attachmentDescription", description);
        await instance.post(`/task/action/file/upload/${task._id}`, formData);
        window.location.reload();
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setFile(null);
        fileRef.current.value = null;
      }
    })();
  }, [file]);

  return (
    <div className="w-full max-w-5xl 2xl:max-w-6xl mx-auto bg-white drop-shadow rounded p-2">
      <ErrorModal message={error} onClear={() => setError(null)} />
      <div className="flex items-center justify-between">
        <h1 className="text-2xl text-gray-600 font-semibold">Files</h1>
        <div className="w-2/3 flex items-center justify-between">
          { role === "freelancer" && (task.taskStatus?.statusname === "approved" || task.taskStatus?.statusname === "waiting offer" || task.taskStatus?.statusname === "assigned") ? (
            ""
          ) : <>
          <input
            type="text"
            name="description"
            id="description"
            style={{width: "70%"}}
            className="w-full rounded-sm lg:w-4/5 p-2"
            placeholder="Enter Files Description Here"
            onChange={(e) => setDescription(e.target.value)}
          />
          <button
            className="bg-sky-500 text-white px-4 py-2 rounded"
            onClick={onAddFile}
          >
            Add File
          </button>
          <input
            type="file"
            multiple
            name="file"
            id="file"
            className="sr-only"
            onChange={(e) => setFile(e.target.files)}
            ref={fileRef}
          />
        </>}
        </div>
      </div>
      {task.attachments && task.attachments.length > 0 ? (
        <AttachmentsTable task={task} attachments={task.attachments} />
      ) : (
        <p className="text-center">No Files Attached</p>
      )}
    </div>
  );
};

export default Attachments;
