import { Field, Form, Formik } from "formik";
import React, { useReducer, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../../../axios";
import { Link } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { validate, VALIDATOR_MINLENGTH } from "../../../../util/validators";

const initialValues = {
  description: "",
  deadline: ""
};

const descriptionReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.description,
        isvalid: validate(action.description, action.validators),
      };
    case "TOUCH":
      return {
        ...state,
        isTouched: true,
      };
    default:
      return state;
  }
};

const AddEdit = ({}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [descriptionState, dispatch5] = useReducer(descriptionReducer, {
    value: "",
    isvalid: false,
    isTouched: false,
  });

  const descriptionChangeHandler = (event) => {
    dispatch5({
      type: "CHANGE",
      description: event.target.value,
      validators: [VALIDATOR_MINLENGTH(3)],
    });
  };
  const descriptionTouchHandler = () => {
    dispatch5({
      type: "TOUCH",
    });
  };

  const [attachment, setAttachment] = useState();
  const attachmentRef = useRef();
  const handleFileChange = (file) => {
    setAttachment(file);
  };

  const onSubmit = async (values) => {
    const endpoint = `/task/action/edit/add/${id}`;
    const formData = new FormData();
    for (const key in values) {
      formData.append(key, values[key]);
    }
    if (attachment) {
      for (let i = 0; i < attachment.length; i++) {
        const f = attachment[i];
        const fileName = f.name;
        const encodedFilename = encodeURIComponent(fileName);
        formData.append("attachment", f, encodedFilename);
      }
      formData.append("attachmentDescription", descriptionState.value);
    }
    try {
      setIsLoading(true);
      await axios.post(endpoint, formData);
      navigate(`/task/${id}`);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.err);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full p-3 min-h-[calc(100vh-65px)]">
      <div className="relative flex flex-row justify-center w-full p-1 mb-4">
        <Link to={`/task/${id}`} className="absolute top-0 left-0 p-2 text-3xl">
          <TiArrowBack />
        </Link>
        <h2 className="text-center text-2xl font-bold lg:text-3xl">Add Edit</h2>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className="mx-auto max-w-lg flex flex-col items-center mt-4">
          <div className="mb-4">
            <label
              className="block mb-2 text-lg font-medium text-gray-700"
              htmlFor="description"
            >
              Description
            </label>
            <Field
              disabled={isLoading}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              type="text"
              name="description"
              id="description"
            />
          </div>

          <div className="mb-4">
            <label
              className="block mb-2 text-lg font-medium text-gray-700"
              htmlFor="deadline"
            >
              Deadline
            </label>
            <Field
              disabled={isLoading}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              type="datetime-local"
              name="deadline"
              id="deadline"
            />
          </div>

          <div className="mb-4 col-span-2 flex flex-col w-full">
            <label className="w-full font-bold">Attachment</label>
            <div className="relative">
              <input
                ref={attachmentRef}
                type="file"
                multiple
                id="attachment"
                name="attachment"
                className="sr-only"
                onChange={(event) => handleFileChange(event.target.files)}
              />
              <button
                type="button"
                className="bg-blue-500 lg:w-full text-white rounded py-1 px-3 font-bold w-full transition-all hover:bg-blue-400 active:scale-95"
                onClick={() => attachmentRef.current.click()}
              >
                Choose File
              </button>
              <span className="ml-2">
              {Array.from(attachment || []).map((file, index) => (
                <span className="ml-2" key={index}>{file.name}</span>
              ))}
             {attachment && attachment.length === 0 ? (
              <span>No File Chosen</span>
             ) : null}
            </span>
            </div>
          </div>

          <div className="mb-4 col-span-2 flex flex-col w-full">
            <label className="w-full font-bold">Attachment Description</label>
            <textarea
              placeholder="Attachment Description"
              value={descriptionState.value}
              onChange={descriptionChangeHandler}
              onBlur={descriptionTouchHandler}
              isvalid={descriptionState.isvalid.toString()}
              className={`w-full ml-2 rounded-sm lg:w-full p-2${
                !descriptionState.isvalid &&
                descriptionState.isTouched &&
                "border-red-500"
              }`}
            ></textarea>
          </div>

          <div className="flex items-center justify-between">
            <button
              disabled={isLoading}
              type="submit"
              className="bg-blue-500 text-white px-3 py-1 rounded-md"
            >
              Add
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default AddEdit;
