import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import DateFilter from "../../../DateFilter";
import Filter from "../../../Filter";
import { useNavigate } from "react-router";
import TasksTable from "../../../TasksTable/TasksTable";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import instance from "../../../../axios";
import { useDebounce } from "use-debounce";
import { FaSearch } from "react-icons/fa";

const Tasks = ({ type }) => {
  const [tasksCount, setTasksCount] = useState(0);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [theType, setTheType] = useState("");

  const [specialities, setSpecialities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [countries, setCountries] = useState([]);
  const [clients, setClients] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [subSpeciality, setSubSpeciality] = useState("");
  const [status, setStatus] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [selectedRange, setSelectedRange] = useState({
    start,
    end,
  });
  const [client, setClient] = useState("");
  const [country, setCountry] = useState("");

  const [searchName, setSearchName] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        theType !== type && setSearch("");
        const params = {
          page,
          limit,
          start: selectedRange.start,
          end: selectedRange.end,
          client,
          country,
          status,
          speciality,
          subSpeciality,
          search,
        };
        const [statusRes, specialityRes, countryRes, clientRes, taskRes] =
          await Promise.all([
            instance.get(`/status/filter/all/`),
            instance.get(`/speciality/`),
            instance.get(`/country/`),
            instance.get(`/client/`),
            instance.get(`/task/`, {
              params,
            }),
          ]);

        setStatuses(statusRes.data.statuses);
        setSpecialities(specialityRes.data.specialities);
        setCountries(countryRes.data.countries);
        setClients(clientRes.data.clients);
        setTasksCount(taskRes.data[`${type}Count`]);
        setTasks(taskRes.data[type]);
        setTheType(type);
        
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          setError({
            type: "error",
            message: error.response.data.err || "Error",
          });
        } else {
          setError({ type: "error", message: error.message || "Error" });
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    type,
    page,
    limit,
    selectedRange,
    search,
    client,
    country,
    status,
    speciality,
    subSpeciality,
  ]);

  const [datePickerOpen] = useState(false);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  useEffect(() => {
    if (start && end) {
      setSelectedRange({ start, end });
      setPage(1);
    }
  }, [start, end]);

  const dateHandler = async () => {
    setIsLoading(true);
    try {
      setError(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}:5001/api/task/filter/result`,
        {
          start: start,
          end: end,
        }
      );
      const responseData = await response;
      if (!(response.statusText === "OK")) {
        throw new Error(responseData.data.message);
      }
      setTasks(response.data.tasks);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err.message && "SomeThing Went Wrong , Please Try Again .");
    }
  };

  const clearFilterHandler = () => {
    setClient("");
    setSearchName("");
    setCountry("");
    setSpeciality("");
    setStatus("");
    setStart("");
    setEnd("");
    setSubSpeciality("");
  };

  const [filterOpen, setFilterOpen] = useState(false);
  const navigate = useNavigate();

  const onFirstPage = () => {
    setPage(1);
  };
  const onLastPage = () => {
    setPage(Math.ceil(tasksCount / limit));
  };
  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const onPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <>
      <ErrorModal message={error} onClear={() => setError(null)} />
      <div
        className={`transition-all min-h-[calc(100vh-65px)] ${
          filterOpen ? "ml-44" : "ml-16"
        } py-4 flex flex-col space-y-2`}
      >
        {type === "myTasks" && <Filter
          filterOpen={filterOpen}
          setFilterOpen={setFilterOpen}
          clear={clearFilterHandler}
        >

          <select
            id="status"
            name="status"
            className="w-full"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="" className="text-secondary">
              Statuses
            </option>
            {statuses.map((status) => (
              <option value={status._id} key={status._id}>
                {status.statusname}
              </option>
            ))}
          </select>
        </Filter>}
        <div className="flex justify-between items-center">
          <h1 className="text-2xl">Tasks</h1>
          <div className="">
            <DateFilter
              startDate={start}
              endDate={end}
              onChange={onChange}
              datePickerOpen={datePickerOpen}
              dateHandler={dateHandler}
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
            }}
            className="w-1/3 relative"
          >
            <button type="submit" className="absolute top-3 right-3">
              <FaSearch />
            </button>
            <input
              name="search"
              type="text"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              placeholder={"Search By Name or ID"}
            />
          </form>
        </div>

        {tasks && tasks.length > 0 ? (
          <TasksTable
            pagination
            page={page}
            totalPages={Math.ceil(tasksCount / limit)}
            onFirstPage={onFirstPage}
            onLastPage={onLastPage}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
            tasks={tasks}
            type={type}
          />
        ) : (
          <div className="row  p-3 m-0 text-center">
            <h2>There Is No Tasks</h2>
          </div>
        )}
      </div>
    </>
  );
};

export default Tasks;
