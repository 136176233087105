import React from "react";
import { FaBell } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import instance from "../axios";

const NotificationCard = ({
  notification,
  setNotification,
  realtime = false,
}) => {
  const onNotificationClick = async () => {
    if (notification.link) {
      const { data } = await instance.put(
        `/webNotification/${notification._id}`
      );
      console.log(data);
      window.location.href = notification.link;
    }
  };

  return (
    <div className={`relative p-4 rounded-lg shadow-md bg-white`}>
      {realtime && (
        <IoIosCloseCircle
          onClick={() => setNotification(null)}
          className="cursor-pointer absolute w-5 h-5 text-gray-500 right-2 top-3"
        />
      )}
      <div
        className={`${
          notification.seen ? "bg-gray-400" : "bg-blue-500"
        } h-full w-2 absolute left-0 top-0 rounded-l-lg`}
      ></div>
      <div
        onClick={onNotificationClick}
        className={`flex items-start ${notification.link && "cursor-pointer"}`}
      >
        <div className="flex items-center justify-center w-8 h-8 px-2 mr-4 rounded-full bg-blue-500 text-white">
          <FaBell className="text-2xl" />
        </div>
        <div>
          <p className="text-base font-medium text-gray-900">
            {notification.body}
          </p>
          <p className="text-sm text-gray-500">
            {new Date(notification.createdAt).toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
