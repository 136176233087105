import React from 'react';
import { format } from 'date-fns';

const Notes = ({ title, data, height }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  return (
    <div className={`bg-white drop-shadow rounded p-4 flex-1 overflow-y-auto ${height ? `max-h-${height}` : ''}`}>
      <h2 className="text-xl text-gray-700 font-semibold mb-3">{title}</h2>
      <div className="space-y-3">
        {data.length > 0 ? (
          data.map((e) => (
            <div
              key={e._id}
              className="p-2 rounded-lg shadow-md bg-white"
            >
              <div className="flex flex-col">
                <p className="text-base font-medium text-gray-900 mb-2">
                  {e.content}
                </p>
                <span className="text-sm text-gray-500">
                  {formatDate(e.createdAt)}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="col-12 my-2 fw-bold p-3">
            <p>There Is No {title}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notes;