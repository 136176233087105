import React, { useState } from "react";
import OfferSubmitted from "./Progress/OfferSubmitted";
import Done from "./Progress/Done";
import Delivered from "./Progress/Delivered";
import WaitingOffer from "./Progress/WaitingOffer";

const Status = ({ status, task, setTask, offer, percentages }) => {
  const availableStatuses = [
    "offer submitted",
    "done",
    "delivered",
    "edit",
    "waiting offer",
    "rejected",
    "pending",
  ];
  const [statusState, setStatusState] = useState(status);
  const renderStatus = () => {
    switch (statusState) {
      case "offer submitted":
        return (
          <OfferSubmitted
            offer={offer}
            taskId={task._id}
            cost={task.cost}
            setStatus={setStatusState}
            currency={task.task_currency.currencyname}
            setTask={setTask}
          />
        );
      case "pending":
        return (
          <OfferSubmitted
            task={task}
            offer={offer}
            taskId={task._id}
            cost={task.cost}
            setStatus={setStatusState}
            currency={task.task_currency.currencyname}
            setTask={setTask}
          />
        );
      case "done":
        return (
          <Done
            task={task}
            file={task.file}
            taskId={task._id}
            setStatus={setStatusState}
            freelancer={task.freelancer?.freelancername}
            setTask={setTask}
          />
        );
      case "delivered":
        return (
          <Delivered
            taskId={task._id}
            edits={task.edits}
            setTask={setTask}
            task={task}
            percentages={percentages}
          />
        );
      case "edit":
        return (
          <Delivered
            taskId={task._id}
            edits={task.edits}
            setTask={setTask}
            task={task}
            percentages={percentages}
          />
        );
      case "waiting offer":
        return (
          <WaitingOffer
            taskId={task._id}
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );
      case "rejected":
        return (
          <WaitingOffer
            taskId={task._id}
            rejected
            setStatus={setStatusState}
            offers={task.offers}
            setTask={setTask}
            task={task}
          />
        );
      default:
        return;
    }
  };

  return (
    <div
      className={`${
        !availableStatuses.includes(statusState) && "hidden"
      } w-full max-w-5xl 2xl:max-w-6xl mx-auto bg-white shadow rounded p-2`}
    >
      {/* <h1 className="text-2xl text-gray-600 font-semibold mb-0">Status</h1>
      <p className="mb-2 p-0 text-gray-400 font-semibold text-sm capitalize">
        {statusState.split("-").join(" ")}
      </p> */}
      {renderStatus()}
    </div>
  );
};

export default Status;
