import React, { useState } from 'react';

const FileListTable = ({ attachment, descriptions, setDescriptions }) => {
//   const [descriptions, setDescriptions] = useState({});

  const handleDescriptionChange = (fileName, value) => {
    setDescriptions(prev => ({ ...prev, [fileName]: value }));
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      {attachment && attachment.length > 0 ? (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">File Name</th>
                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Array.from(attachment).map((file, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                  <td className="px-6 py-1 whitespace-nowrap text-center text-sm font-medium text-gray-900">{file.name}</td>
                  <td className="px-6 py-1 whitespace-nowrap">
                    <input
                      type="text"
                      className="w-full px-3 py-2 text-sm font-medium text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                      placeholder="Enter description"
                      value={descriptions[file.name] || ''}
                      onChange={(e) => handleDescriptionChange(file.name, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center py-4 text-gray-500">No File Chosen</div>
      )}
    </div>
  );
};

export default FileListTable;