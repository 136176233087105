import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../../../LoadingSpinner/LoadingSpinner";
import Filter from "../../../Filter";
import { useNavigate } from "react-router";
import TasksTable from "../../../TasksTable/TasksTable";
import { useDebounce } from "use-debounce";
import instance from "../../../../axios";
import ErrorModal from "../../../../LoadingSpinner/ErrorModal";
import { FaSearch } from "react-icons/fa";

// statuses filter
const getStatusFilter = (status, tasks) => {
  if (!status) {
    return tasks;
  }
  return tasks.filter((tasks) => tasks.taskStatus._id.includes(status));
};

const PendingTasks = () => {
  const [tasksCount, setTasksCount] = useState(0);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [tasks, setTasks] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState("");

  const [searchName, setSearchName] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const params = { page, limit, search, status };
        const promises = [
          instance.get(`/status/filter/all/`),
          instance.get(`/task/`, {
            params,
          }),
        ];

        const [statusesRes, tasksRes] = await Promise.all(promises);
        setStatuses(statusesRes.data.statuses);
        setTasksCount(tasksRes.data.pendingTasksCount);
        setTasks(tasksRes.data.pendingTasks);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          setError({ type: "error", message: error.response.data.err });
        } else {
          setError({ type: "error", message: error.message });
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [page, limit, search, status]);

  const [filterOpen, setFilterOpen] = useState(false);

  const onFirstPage = () => {
    setPage(1);
  };
  const onLastPage = () => {
    setPage(Math.ceil(tasksCount / limit));
  };
  const onNextPage = () => {
    setPage((prev) => prev + 1);
  };
  const onPreviousPage = () => {
    setPage((prev) => prev - 1);
  };

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div
      className={`transition-all min-h-[calc(100vh-65px)] ${
        filterOpen && "ml-44"
      } py-4 flex flex-col space-y-2`}
    >
      <ErrorModal message={error} onClear={() => setError(null)} />
      {/* <Filter filterOpen={filterOpen} setFilterOpen={setFilterOpen}>
        <select
          id="status"
          name="status"
          className="w-full"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        >
          <option value="" disabled className="text-secondary">
            Statuses
          </option>
          {statuses.map((status) => (
            <option value={status._id} key={status._id}>
              {status.statusname}
            </option>
          ))}
        </select>
      </Filter> */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl">Pending Tasks</h1>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setSearch(e.target.search.value);
        }}
        className="w-1/3 relative"
      >
        <button type="submit" className="absolute top-3 right-3">
          <FaSearch />
        </button>
        <input
          name="search"
          type="text"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          placeholder={"Search By Name or ID"}
        />
      </form>
      {tasks && tasks.length > 0 ? (
        <TasksTable
          pagination
          page={page}
          totalPages={Math.ceil(tasksCount / limit)}
          onFirstPage={onFirstPage}
          onLastPage={onLastPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          tasks={tasks}
        />
      ) : (
        <div className="row  p-3 m-0 text-center">
          <h2>There Is No Tasks</h2>
        </div>
      )}
    </div>
  );
};

export default PendingTasks;
