import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import axios from "../../../../../axios";
import { emitTaskNotification } from "../../../../../util/io";

const Assigned = ({ taskId, freelancer, setStatus, setTask, offers, task }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [freelancers, setFreeLancers] = useState([]);
  const [selectedFreelancer, setSelectedFreelancer] = useState(null);
  const [costValue, setCostValue] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        setFreeLancers(offers.map((offer) => offer.freelancer));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.respose) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
      }
    })();
  }, []);

  const handleProceed = async () => {
    if (!selectedFreelancer) return;
    if (!costValue || costValue <= 0) return alert("Invalid cost");
    try {
      setIsLoading(true);
      await axios.post(`/task/action/assign/${taskId}`, {
        freelancer: selectedFreelancer,
        cost: costValue,
      });
      setStatus("assigned");
      emitTaskNotification(
        `Task No: ${task.serialNumber} - Status Changed From Approved To Assigned`,
        task
      );
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data);
      } else {
        console.log(error);
      }
    }
  };

  return isLoading ? (
    <div className="flex items-center justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="space-y-2">
      <div className="flex items-end justify-between">
        <div className="w-1/3">
          <label htmlFor="cost" className="text-gray-400 font-semibold">
            Change Freelancer
          </label>
          <ReactSelect
            onChange={(e) => {
              setSelectedFreelancer(e.value);
              setCostValue(offers.filter((offer) => offer.freelancer._id === e.value)[0].offer);
            }}
            options={freelancers.map((freelancer) => ({
              label: freelancer.freelancername,
              value: freelancer._id,
            }))}
          />
        </div>
        <button
          onClick={handleProceed}
          className="bg-blue-500 text-white px-4 py-2 rounded active:scale-95"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default Assigned;
