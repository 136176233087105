import React, { useState } from "react";
import LoadingSpinner from "../../../../../LoadingSpinner/LoadingSpinner";
import { FiCheck, FiCheckCircle, FiClock, FiSlash, FiXCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import axios from "../../../../../axios";
import { IoCodeWorking } from "react-icons/io5";

const Delivered = ({ taskId, edits = [], setTask, task }) => {
  const [isLoading, setIsLoading] = useState(false);

  return isLoading ? (
    <div className="w-full flex items-center justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div className="">
      {edits && edits.length > 0 ? (
        <table className="table-auto border border-collapse w-full text-center">
          <thead>
            <tr>
              <th className="border px-4 py-2 w-3/12">Description</th>
              <th className="border px-4 py-2">Deadline</th>
              <th className="border px-4 py-2">Note</th>
              <th className="border px-4 py-2">Cost</th>
              <th className="border px-4 py-2 w-1/12">Actions</th>
            </tr>
          </thead>
          <tbody>
            {edits.map((edit, index) => (
              <tr key={index}>
              <td className="border px-4 py-2">{edit.description}</td>
              <td className="border px-4 py-2">{`${edit.deadline?.split("T")[0]} ${edit.deadline?.split("T")[1]?.split(".")[0]}`}</td>
              <td className="border px-4 py-2">{edit.note}</td>
              <td className="border px-4 py-2">{edit.cost}</td>
              <td className="border px-4 py-2">
                {edit.status === "done" ? (
                  <FiCheck className="text-green-500 w-6 h-6 mx-auto" />
                ) : edit.status === "delivered" ? (
                  <FiCheckCircle className="text-green-500 w-6 h-6 mx-auto" />
                ) : edit.status === "pending" ? (
                  <FiClock className="text-yellow-500 w-6 h-6 mx-auto" />
                ) : edit.status === "included" || edit.status === "accepted" ? (
                  <IoCodeWorking className="text-yellow-500 w-6 h-6 mx-auto" />
                ) : edit.status === "not included" ? (
                  <FiClock className="text-yellow-500 w-6 h-6 mx-auto" />
                ) : edit.status === "rejected" ? (
                  <FiXCircle className="text-red-500 w-6 h-6 mx-auto" />
                ) : (
                  <FiSlash className="text-green-500 w-6 h-6 mx-auto" />
                )}
              </td>
            </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center">No edits made</p>
      )}
    </div>
  );
};

export default Delivered;
